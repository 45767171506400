import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import SpecDesign from './pages/SpecDesign';


function App() {
  return (
    <Router>
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route exact path="/specDesign" element={<SpecDesign/>} />
      {/* Add more routes as needed */}
    </Routes>
  </Router>
  );
}

export default App;
