export const langData = [
    {name:"React/Java",value:"react-java"},
    {name:"Java",value:"java"},
    {name:"React",value:"react"},
    {name:"Nodejs",value:"nodejs"}
]

export const appData = [
    {name:"Web Application",value:"web application"},
    {name:"Front-End App",value:"ui"},
    {name:"Back-End System",value:"backend"}
]