import React from 'react';
import { Box, Button, Step, StepLabel, Stepper, styled,LinearProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';


const StyledLabelContainer = styled("div")({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center"
});
const StyledStepper = styled("ul")({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-around",
  padding: 0,
  width: "100%"
});
const StyledStepperStep = styled("li")({
  position: "relative",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-around",
  alignItems: "center",
  width: "100%"
});
const StyledStepperStepIndex = styled("div")(({ currentStep, done }) => ({
  // width: "30px",
  // height: "30px",
  // lineHeight: "30px",
  // borderRadius: "50%",
  // background: currentStep || done ? "orange !important" : "#dedede",
  // color: currentStep || done ? "#fff" : "#999",
  textAlign: "center",
  marginBottom: "5px"
}));




const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: ownerState.active ? theme.palette.success.main : theme.palette.grey[400],
  display: 'flex',
  height: 30,
  alignItems: 'center',
}));

function StepIcon({ active, completed, className }) {
  return (
    <StepIconRoot ownerState={{ active:completed }}>
      {completed ? (
        <CheckCircleIcon fontSize="large" className={className} style={{ color: '#4CAF50' }} />
      ) : (
        <RadioButtonUncheckedIcon fontSize="large" className={className} />
      )}
    </StepIconRoot>
  );
}

function renderStep(label, key,activeStep) {
  const done = key < activeStep;
  const currentStep = key === activeStep;
  return (
    <StyledStepperStep key={key}>
      <StyledLabelContainer>
        <StyledStepperStepIndex currentStep={currentStep} done={done}>
          <StepIcon completed={done} index={key} />
        </StyledStepperStepIndex>
        <p >{label}</p>

      </StyledLabelContainer>
      {!!key && (
        <ProgressBar current={activeStep} step={key} />
      )}
    </StyledStepperStep>
  );
}
const ProgressBar = ({ current, step }) => {
  const getProps = (current, step) => {
    if (current === step) {
      return {color:"warning"};
    } else if (current > step) {
      return { variant: "determinate",color:"success",value:"100" };
    }
    return { variant: "determinate",value:"0",color:"inherit" };
  };


  const props = getProps(current,step)
   
  return <LinearProgress {...props} sx={{
    "&.MuiLinearProgress-root": {
      flex: "1 1 auto",
      position: "absolute",
      top: 12,
      left: "calc(-50% + 20px)",
      right: "calc(50% + 20px)",

    },
  //  "& .MuiLinearProgress-bar": {
  //           backgroundColor: "#ffd8ba61"
  //         }
  }} />
}

const ProgressStepper = ({ activeStep, steps }) => {
  return (
    <Box sx={{ width: '100%', marginTop: '4em', display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="#loaderBlock">
      


<StyledStepper>
{steps.map((label, index) => renderStep(label, index, activeStep))}

    </StyledStepper>
    </Box>
  ); 
};

export default ProgressStepper;
