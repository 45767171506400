import React, { useState } from "react";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import { saveAs } from 'file-saver';
import htmlDocx from "html-docx-js/dist/html-docx";
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ProcessedCodeContainer = ({
  techDocContent,
  reqDocContent,
  pseudoCodeContent,
  refLink,
}) => {
  const [tabValue, setTabValue] = useState("tech-doc");
  const [techDoc, setTechDoc] = useState(techDocContent);
  const [reqDoc, setReqDoc] = useState(reqDocContent);
  const [editorValue, setEditorValue] = useState(techDoc);
  const [pdfLoading, setPdfLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setEditorValue(newValue === "tech-doc" ? techDoc : reqDoc);
  };
  const handleEditorChange = (value, source) => {
    if (source !== "api") {
      if (tabValue === "tech-doc") {
        setTechDoc(value);
      } else {
        setReqDoc(value);
      }
      setEditorValue(value);
    }
  };
  const handleDownload = async () => {
    const content = tabValue === "tech-doc" ? techDoc : reqDoc;

    const docx = htmlDocx.asBlob(content);

    saveAs(
      docx,
      tabValue === "tech-doc" ? "technical_spec.docx" : "functional_spec.docx"
    );
  };

  const handlePdfDownload = async () => {
    try {
      setPdfLoading(true);

      const pdfContent = htmlToPdfmake(pseudoCodeContent);

      // Define the PDF document
      const docDefinition = {
        content: pdfContent,
      };

      // Create the PDF and download it
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBlob((pdfBlob) => {
        saveAs(pdfBlob, 'pseudo_code.pdf');
      });
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setPdfLoading(false);
    }
  };

  return (
    <Container sx={{ width: "80%" }}>
      <Box my={4} ref={refLink} >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} mb={4}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="secondary tabs example"
          >
            <Tab value="tech-doc" label="Technical Specifications" />
            <Tab value="req-doc" label="Functional Specifications" />
          </Tabs>
        </Box>
        <Box px={4} mb={4} id="#rteBlock">
          <ReactQuill
            height="500px"
            width="80%"
            theme="bubble"
            value={editorValue}
            onChange={(value, _, source) => handleEditorChange(value, source)}
          />
        </Box>
        <Box px={4} mb={4} align="right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            sx={{
              marginTop: "2em",
              textTransform: "none",
              minWidth: "280px",
              minHeight: "40px",
            }}
          >
            Download
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handlePdfDownload}
            sx={{
              marginTop: "2em",
              textTransform: "none",
              minWidth: "280px",
              minHeight: "40px",
              marginLeft: "2em",
            }}
          >
            {" "}
            {pdfLoading ? (
              <CircularProgress size={20} />
            ) : (
              <span>Download Pseudo code</span>
            )}
          </Button>

        
        </Box>
        <Box px={4} mb={4} align="right">

        <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/specDesign"
            sx={{
              marginTop: "2em",
              textTransform: "none",
              minWidth: "280px",
              minHeight: "40px",
              marginLeft: "2em",

            }}
          >
            Try Spec2Design
          </Button>
          </Box>

      </Box>
    </Container>
  );
};

export default ProcessedCodeContainer;
