import React from 'react';
import Box from '@mui/material/Box';

export const CustomTabPanel = (props) => {
    const { children, value, name, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== name}
        id={`simple-tabpanel-${name}`}
        aria-labelledby={`simple-tab-${name}`}
        {...other}
      >
        {value === name && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }