import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

function HeadingContainer({content}) {
  return (
    <Container maxWidth="md">
      <Box pt={15} pb={6} alignItems="center" gap={4}>
        <Typography
          textAlign={"center"}
          fontWeight={"bold"}
          variant="h3"
          gutterBottom
        >
          {content}
        </Typography>
       
      </Box>
    </Container>
  );
}

export default HeadingContainer;
