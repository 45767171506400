import React, { useState, useCallback, useEffect } from "react";
import { io } from "socket.io-client";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import HeadingContainer from "./HeadingContainer";
import SpecFormContainer from "./SpecFormContainer";
import ProgressContainer from "./ProgressContainer";
import ProcessedDesignContainer from "./ProcessedDesignContainer.js";
import { ToasterMessage } from "../components/ToasterMessage";

import JSZip from "jszip";
import mammoth from "mammoth";

const steps = [
  'Data Preprocessing',
  'Document Analysis',
  'Design Document Generation',
  'Ready For Review',
];
const socket = io(
  "https://code2spec-spec2code-ai.stg.pscloudhub.com/Spec2Design"
);
const headingContent = "Spec2Design"

function SpecDesignContainer() {
  const [activeStep, setActiveStep] = useState(0);
  const [reqDocContent, setReqDocContent] = useState();
  const [techDocContent, setTechDocContent] = useState();
  const [svgFiles,setSvgFiles]=useState();
  const [pngFiles,setPngFiles]=useState();
  const [isToasterOpen, setIsToasterOpen] = useState(false);
  const [id, setId] = useState();
  const rteRef = React.createRef();
  const loaderRef = React.createRef();

  useEffect(() => {
    socket.on("connect", (some) => {
      console.log("Socket.IO Client Connected");
    });

    socket.on("disconnect", () => {
      console.log("Socket.IO Client Disconnected");
    });
    socket.on("message", () => {
      console.log("Socket.IO Inside essage");
    });

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket.off("disconnect");
      socket.off("connect");

      socket.off("message");
    };
  }, []);

  const processZipFile = async (filePath) => {
    try {
      const response = await fetch(
        "https://code2spec-spec2code-ai.stg.pscloudhub.com/files?filename=" +
          filePath
      );

      // const response = await fetch("http://localhost:3000/Archive.zip")
      const blob = await response.blob();
      const zip = await JSZip.loadAsync(blob);

      const extractedFiles = {};

      for (const fileName of Object.keys(zip.files)) {
        const file = zip.files[fileName];
        if (!file.dir) {
          const fileData = await file.async('blob');
          extractedFiles[fileName] = fileData;
        }
      }
      const svgFiles = Object.fromEntries(Object.entries(extractedFiles).filter(([fileName]) => fileName.endsWith('.svg')))
      setSvgFiles(svgFiles);
      const pngFiles = Object.fromEntries(Object.entries(extractedFiles).filter(([fileName]) => fileName.endsWith('.png')))
      setPngFiles(pngFiles);

      const techDocArrayBuffer = await zip
        .file("modern_technical_architecture.docx")
        .async("arraybuffer");
      

      const techDocText = await extractTextFromDocx(techDocArrayBuffer);

      setTechDocContent(techDocText);

      setTimeout(() => {
        document
          .getElementById("#rteBlock")
          ?.scrollIntoView({ behavior: "smooth" });
      }, 2000);
    } catch (error) {
      console.error("Error processing ZIP file:", error);
    }
  };

  const handleCancelModernization = useCallback(() => {
    setActiveStep(0);
    setIsToasterOpen(true);

    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    console.log("Cancel modernization process");
  }, []);

  const handleCloseToaster = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsToasterOpen(false);
  };

  const extractTextFromDocx = async (arrayBuffer) => {
    const {value : text} = await mammoth.convertToHtml({ arrayBuffer });
    return text;
  };


  const handleSubmit = (file, formValues) => {
    if (file) {
      const formData = new FormData();
      formData.append("zipfile", file);
      formData.append(
        "json_data",
        JSON.stringify({
          code_language: formValues.language,
          application_type: formValues.appType,
          domain: formValues.domain,
          subdomain: formValues.subdomain,
          function: formValues.functions,
        })
      );

      fetch("https://code2spec-spec2code-ai.stg.pscloudhub.com/generateDesign", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          const id = data.socket_namespace_id;
          setId(id);
          setActiveStep(1);
          setTimeout(() => {
            document
              .getElementById("#loaderBlock")
              ?.scrollIntoView({ behavior: "smooth", block: "center" });
          }, 3500);
          if (id) {
            socket.on(id, (data) => {
              // Update UI with real-time data
              console.log("Received message:", data);
            });
            socket.on(id, handleSpecificStatusUpdate);

            socket.on(id, (data) => {
              if (data?.errorMessage) {
                socket.off(id);
                handleCancelModernization();
              }
              if (data?.file_path) {
                processZipFile(data.file_path);
                socket.off(id, handleSpecificStatusUpdate);
              }
            });
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    }
  };

  const handleSpecificStatusUpdate = (data) => {
    switch (data) {
      case "Step 2: Code analysis completed":
      case "Sequence architecture design generation is started":
       setActiveStep(2);
        break;
      case "Step 3: Design Document generation done":
        setActiveStep(3);
        break;
      case "Step 4: Ready for review":
        setActiveStep(4);
        break;
    }
  };

  const hasContent = techDocContent;
  return (
    <Container>
      <HeadingContainer content={headingContent}/>
      <SpecFormContainer
        onGenerateRequirements={handleSubmit}
        setReqDocContent={setReqDocContent}
        setTechDocContent={setTechDocContent}
      />
      {activeStep > 0 && (
        <>
          <Divider
            sx={{
              [`&.MuiDivider-root`]: {
                marginTop: "60px",
                marginBottom: "80px",
              },
            }}
          />
          <ProgressContainer
            ref={loaderRef}
            activeStep={activeStep}
            handleCancelModernization={handleCancelModernization}
            steps={steps}
          />
        </>
      )}
      {hasContent && (
        <>
          <Divider
            sx={{
              [`&.MuiDivider-root`]: {
                marginTop: "80px",
                marginBottom: "60px",
              },
            }}
          />
          <ProcessedDesignContainer
            refLink={rteRef}
            techDocContent={techDocContent}
            svgFiles={svgFiles}
            pngFiles={pngFiles}
          />
        </>
      )}
      <ToasterMessage isOpen={isToasterOpen} handleClose={handleCloseToaster} />
    </Container>
  );
}

export default SpecDesignContainer;
