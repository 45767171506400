import React from 'react';
import HomeContainer from '../containers/HomeContainer';
import Layout from '../containers/Layout';
function Home() {
  return (
    <Layout>
    <HomeContainer />
    </Layout>
  );
}

export default Home;