import React from 'react';
import SpecDesignContainer from '../containers/SpecDesignContainer';
import Layout from '../containers/Layout';
function SpecDesign() {
  return (
    <Layout>
    <SpecDesignContainer />
    </Layout>
  );
}

export default SpecDesign;