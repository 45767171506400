import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Typography, Box } from '@mui/material';

const CustomSelect = ({ title, options,onChange }) => {
  const [value, setValue] = useState('');
  const labelId = `${title.toLowerCase().replace(/\s+/g, '-')}-label`; // Define labelId here

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value)
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
      <Typography variant="subtitle1" fontWeight="bold" marginBottom="8px">
        {title}
      </Typography>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id={labelId}>
          Select {title}
        </InputLabel>
        <Select
          labelId={labelId}
          value={value}
          onChange={handleChange}
          label={`Select ${title}`}
          inputProps={{disabled:options.length?false:true}}
        >
          {options? options.map((option) => (
            <MenuItem key={option?.value} value={option?.value}>
              {option?.name}
            </MenuItem>
          )):<MenuItem key="none" value="">
          None
        </MenuItem>}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelect;
