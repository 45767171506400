import React from 'react';
import Container from '@mui/material/Container';
import ProgressStepper from '../components/ProgressStepper';

const ProgressContainer=React.forwardRef(({ activeStep, handleCancelModernization,steps },ref) =>{
  return (
    <Container >
      <div >
      <ProgressStepper  activeStep={activeStep} onCancel={handleCancelModernization} steps={steps} />
      </div>
    </Container>
  );
});

export default ProgressContainer;
