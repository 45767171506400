export const langData = [
    {name:"Java",value:"java"},
    {name:"C",value:"c"},
    {name:"C++",value:"cpp"}
]

export const appData = [
    {name:"Web Application",value:"web application"},
    {name:"Front-End",value:"ui"},
    {name:"Back-End",value:"backend"}
]