import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { bytesToMB } from "../constants";

const FileUploadButton = ({
  uploadTitle,
  uploadInfoText,
  acceptedFileTypes,
  placeholderText,
  isDisabled=false,
  onFileChange,
}) => {
  const [file, setFile] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [error, setError] = useState();

  const inputId = `${uploadTitle.replace(/\s+/g, "-").toLowerCase()}`; // Unique ID based on uploadTitle

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setUploadedFile(true);
      onFileChange(file);
    }
  };

  const handleDeleteFile = () => {
    setFile("");
    setUploadedFile(false);
    onFileChange("");
  };

  const fileName = file?.name;
  const fileSize = file && `(${bytesToMB(file.size)}MB)`;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
      paddingBottom="1.5em"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginBottom="8px"
      >
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{
            color: isDisabled ? "rgba(0, 0, 0, 0.5)" : "inherit",
          }}
        >
          {uploadTitle}
        </Typography>
        <Typography
          variant="caption"
          alignItems="flex-end"
          color="textSecondary"
          style={{ marginTop: "auto" }}
          sx={{
            color: isDisabled ? "rgba(0, 0, 0, 0.5)" : "inherit",
          }}
        >
          {uploadInfoText}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" width="100%">
        {uploadedFile && (
          <TextField
            variant="outlined"
            value={fileName ? `${fileName} ${fileSize}` : ""}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            placeholder={placeholderText}
            disabled={isDisabled}
            sx={{
              bgcolor: fileName ? "" : "rgba(0, 0, 0, 0.05)",
              "& .MuiOutlinedInput-root": {
                borderTopRightRadius: fileName ? 0 : "4px",
                borderBottomRightRadius: fileName ? 0 : "4px",
                border: fileName ? 0 : "1px solid rgba(0, 0, 0, 0.23)",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove outline border
              },
            }}
          />
        )}
        <input
          accept={acceptedFileTypes}
          style={{ display: "none" }}
          id={inputId}
          type="file"
          onChange={handleFileChange}
          disabled={isDisabled}
          onClick={ (event) => {
            event.target.value = ''
        }}
        />
        {fileName ? (
          <label htmlFor={inputId}>
            
            <IconButton
              onClick={handleDeleteFile}
              sx={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                height: "56px",
                color: "red",
              }}
              disabled={isDisabled}
            >
              <DeleteIcon />
            </IconButton>
            
          </label>
        ) : (
          <TextField
            variant="outlined"
            placeholder="No file chosen"
            onClick={() => document.getElementById(inputId).click()}
            fullWidth
            InputProps={{
              readOnly:true,
              endAdornment: (
                <InputAdornment position="end">
                  <label htmlFor="file-input">
                    <Button
                      color="primary"
                      component="span"
                    >
                      Upload File
                    </Button>
                  </label>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Box>
    </Box>
  );
};


export default FileUploadButton;
