import React, { useState,useEffect } from 'react';

import Container from '@mui/material/Container';
import FileUploadButton from '../components/FileUploadButton';
import URLAttachButton from '../components/URLAttachButton';
import CustomSelect from '../components/CustomSelect';
import GenerateRequirementsButton from '../components/GenerateRequirementsButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {businessData} from '../configs/data'
import {langData,appData} from '../configs/codeSpecData';

const content ="Generate Requirements"
function SourceCodeFormContainer({onGenerateRequirements }) {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isUrlAttached, setIsUrlAttached] = useState (false);
  const [language, setLanguage] = useState('');
  const [appType, setAppType] = useState('');

  const [domain, setDomain] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [functions, setFunctions] = useState('');

  const [file,setFile] = useState();
  


  const handleFileUploadChange = (file) => {
    setIsFileUploaded(!!file);
    setFile(file);
  };

  const handleURLAttachChange = (isUrlAttached) => {
    setIsUrlAttached(isUrlAttached);
  };

  
  const handleDomainChange = (value) => {
    setDomain(value);
    setSubdomain('');
    setFunctions('');

  };

  const handleSubdomainChange = (value) => {
    setSubdomain(value);
    setFunctions('');
  };

  const handleFunctionsChange = (value) => {
    setFunctions(value);
  };

  const subdomainOptions = domain? Object.keys(businessData[domain].subdomains).map((key) => (
      {name:businessData[domain].subdomains[key].name,
        value:key
      }
  )):''

  const functionOptions = subdomain  ? Object.keys(businessData[domain].subdomains[subdomain].functions).map((key) => (
    {name:businessData[domain].subdomains[subdomain].functions[key],
      value:key
    }
)):''

const handleGenerateReq = () =>{
  const formData = {
    subdomain,
    domain,
    language,
    appType,
    functions
  }
  onGenerateRequirements(file, formData)
}
const isFormValid = (isFileUploaded || isUrlAttached) && subdomain && domain && language && appType && functions;
  return (
    <Container sx={{ width: '80%' }}>
      <FileUploadButton
        uploadTitle={"Upload Code"}
        uploadInfoText={"Support Format: ZIP, Max Limit: 8 MB"}
        placeholderText={"No file chosen"}
        acceptedFileTypes={".zip"}
        isDisabled={isUrlAttached}
        onFileChange={handleFileUploadChange}
      />
      <URLAttachButton
        uploadTitle={"Or Attach URL"}
        isDisabled={isFileUploaded}
        onAttach={handleURLAttachChange}
      />
      <FileUploadButton
        uploadTitle={"Upload Contextual Data Documents"}
        uploadInfoText={"Supported Format: PDF/ DOC"}
        placeholderText={"No file chosen"}
        acceptedFileTypes={".pdf, .doc, .docx"}
        onFileChange={handleFileUploadChange}
      />
      <Divider  sx={{
           [`&.MuiDivider-root`]: { marginTop:'30px'
          },
          [`& .MuiDivider-wrapper`]: { paddingLeft:0
          },
          [`&.MuiDivider-root::before`]: { width: 0,marginTop:'20px'
        },}}
        textAlign="left">TECHNICAL DEFINITION</Divider>

      <Box my={4} display="flex" justifyContent="space-between" width="100%" gap="1em">
        <Box flex={1}>
          <CustomSelect title="Code Language" value={language} onChange={(value)=>setLanguage(value)} options={langData} />
        </Box>
        <Box flex={1}>
          <CustomSelect title="Application Type" value={appType} onChange={(value)=>setAppType(value)} options={appData} />
        </Box>
      </Box>

      <Divider  sx={{
          [`&.MuiDivider-root`]: { marginTop:'30px'
        },
          [`&.MuiDivider-root::before`]: { width: 0
        },[`& .MuiDivider-wrapper`]: { paddingLeft:0
        },}} textAlign="left">BUSINESS DEFINITION</Divider>
      <Box my={4} display="flex" justifyContent="space-between" width="100%" gap="1em">
        <Box flex={1}>
          <CustomSelect title="Domain" value={domain} onChange={handleDomainChange} options={[{name:"Banking",value:"banking"}]} />
        </Box>
        <Box flex={1}>
          <CustomSelect title="Sub-Domain" value={subdomain} onChange={handleSubdomainChange} options={subdomainOptions} />
        </Box>
        <Box flex={1}>
          <CustomSelect title="Function" value={functions} onChange={handleFunctionsChange} options={functionOptions} />
        </Box>
      </Box>
      <GenerateRequirementsButton
        isFileUploaded={isFileUploaded}
        isUrlAttached={isUrlAttached}
        generateRequirements={handleGenerateReq}
        isDisabled={!isFormValid}
        content={content}

      />
    </Container>
  );
}

export default SourceCodeFormContainer;
