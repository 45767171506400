import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import { saveAs } from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";
import { Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { CustomTabPanel } from "../components/CustomTabPanel";
import { removeFileExtension } from "../utils/file-utils";

export const ProcessedDesignContainer = ({
  techDocContent,
  svgFiles,
  pngFiles,
  refLink,
}) => {
  const [tabValue, setTabValue] = useState("tech-doc");
  const [techDoc, setTechDoc] = useState(techDocContent);
  const [editorValue, setEditorValue] = useState(techDoc);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setEditorValue(techDoc);
  };
  const handleEditorChange = (value, source) => {
    if (source !== "api") {
      if (tabValue === "tech-doc") {
        setTechDoc(value);
      }
      setEditorValue(value);
    }
  };
  const handleDownload = async () => {
    if (tabValue == "tech-doc") {
      const content = techDoc;

      const docx = htmlDocx.asBlob(content);

      saveAs(docx, "modern_technical_architecture.docx");
    } else {
      const blob = svgFiles[tabValue] || pngFiles[tabValue];
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = tabValue;
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Container sx={{ width: "80%" }}>
      <Box my={4} ref={refLink}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} mb={4}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="secondary tabs example"
            variant="scrollable"
            scrollButtons={false}
          >
            <Tab value="tech-doc" label="Modern Technical Architecture" />

            {Object.keys(svgFiles).map((fileName, index) => (
              <Tab value={fileName} label={removeFileExtension(fileName)} />
            ))}
            {Object.keys(pngFiles).map((fileName, index) => (
              <Tab value={fileName} label={removeFileExtension(fileName)} />
            ))}
          </Tabs>
        </Box>
        <Box px={4} mb={4} id="#rteBlock">
          <CustomTabPanel value={tabValue} index={0} name="tech-doc">
            <ReactQuill
              height="500px"
              width="80%"
              theme="bubble"
              value={editorValue}
              onChange={(value, _, source) => handleEditorChange(value, source)}
            />
          </CustomTabPanel>
          {Object.keys(pngFiles)?.map((fileName, index) => (
            <CustomTabPanel value={tabValue} key={index} name={fileName}>
              <img
                src={URL.createObjectURL(pngFiles[fileName])}
                alt={fileName}
                style={{ width: "100%" }}
              />
            </CustomTabPanel>
          ))}
          {Object.keys(svgFiles)?.map((fileName, index) => (
            <CustomTabPanel value={tabValue} key={index} name={fileName}>
              <img
                src={URL.createObjectURL(
                  new Blob([svgFiles[fileName]], { type: "image/svg+xml" })
                )}
                alt={fileName}
                style={{ width: "100%" }}
              />
            </CustomTabPanel>
          ))}
        </Box>
        <Box px={4} mb={4} align="right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            sx={{
              marginTop: "2em",
              textTransform: "none",
              minWidth: "280px",
              minHeight: "40px",
            }}
          >
            Download
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ProcessedDesignContainer;
