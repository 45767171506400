import React, { useState } from "react";
import { Button, TextField, Typography, Box, IconButton,InputAdornment } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { urlPattern, buttonErrorColor } from "../constants";

const URLAttachButton = ({
  uploadTitle,
  buttonLabel='Attach',
  onAttach,
  isDisabled=false,
}) => {
  const [url, setUrl] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isUrlEntered, setIsUrlEntered] = useState(false);

  const handleUrlChange = (event) => {
    const newUrl = event.target.value;
    setUrl(newUrl);
  };

  const isValidUrlFormat = (url) => {
    return urlPattern.test(url);
  };

  const handleAttachClick = () => {
    setIsUrlEntered(true); // Set isUrlEntered to true only when Attach button is clicked
    setIsValidUrl(isValidUrlFormat(url));
    onAttach(isValidUrlFormat(url));
  };

  const handleDeleteUrl = () => {
    setUrl("");
    setIsValidUrl(false);
    setIsUrlEntered(false);
    onAttach(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
      paddingBottom="1.5em"
    >
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        marginBottom="8px"
        sx={{
          color: isDisabled ? "rgba(0, 0, 0, 0.5)" : "inherit",
        }}
      >
        {uploadTitle}
      </Typography>
      <Box display="flex" alignItems="center" width="100%">
        <TextField
          variant="outlined"
          value={url}
          fullWidth
          onChange={handleUrlChange}
          placeholder="Enter URL"
          error={!isValidUrl && isUrlEntered} // Show error when URL is not valid and isUrlEntered is true
          //helperText={!isValidUrl && isUrlEntered ? 'Invalid URL format' : ''} - want to add this but not sure how to make it not break the theming just yet
          disabled={isDisabled}
          sx={{
            bgcolor: isValidUrl
              ? "#fff"
              : isDisabled
              ? "rgba(0, 0, 0, 0.1)"
              : "rgba(255,255,255)",
           
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isValidUrl ? (
                  <IconButton
                    onClick={handleDeleteUrl}
                    sx={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      height: "56px",
                      color: buttonErrorColor, // Use the extracted error color
                    }}
                    disabled={isDisabled}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <Button
                    onClick={handleAttachClick}
                    disabled={isDisabled}
                    sx={{
                      textTransform: "none",
                      whiteSpace: "nowrap",
                      height: "56px",
                      color: isUrlEntered ? buttonErrorColor : undefined, // Set text color to red if URL is not valid and has been entered
                      
                    }}
                  >
                    {buttonLabel}
                  </Button>
                )}
              </InputAdornment>
            ),
          }}
        />
        {/* {isValidUrl ? (
          <IconButton
            onClick={handleDeleteUrl}
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              height: "56px",
              color: buttonErrorColor, // Use the extracted error color
            }}
            disabled={isDisabled}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <Button
            variant="outlined"
            onClick={handleAttachClick}
            disabled={isDisabled}
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              height: "56px",
              borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
              color: !isValidUrl && isUrlEntered ? buttonErrorColor : undefined, // Set text color to red if URL is not valid and has been entered
              borderColor:
                !isValidUrl && isUrlEntered ? buttonErrorColor : "inherit", // Use the extracted error color
              "&:hover": {
                borderColor:
                  !isValidUrl && isUrlEntered ? buttonErrorColor : "inherit", // Use the extracted error color for hover state
              },
            }}
          >
            {buttonLabel}
          </Button>
        )} */}
      </Box>
    </Box>
  );
};

// URLAttachButton.defaultProps = {
//   buttonLabel: "Attach",
//   isDisabled: false,
// };

export default URLAttachButton;
