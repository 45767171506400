import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";
import Typography from '@mui/material/Typography';
const drawerWidth = 240;

export default function Layout({ children }) {
  const theme = useTheme();
  const location = useLocation();
  const routes = [
    { path: "/", iconText: "C.S", label: "Code2Spec" },
    { path: "/specDesign", iconText: "S.D", label: "Spec2Design" },
    { path: "/backlogAI", iconText: "B.A", label: "Backlog AI" },
  ];

  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ background: "#fff" }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: 'none' }}
      >
        <Toolbar sx={{ justifyContent: "space-between", padding: '0 !important', display: 'block' }}>
          <picture style={{ textAlign: 'center', padding:'0', margin:'0', position: 'static' }}>
            <img
              src={'./topbar.png'}
              alt='PS'
              height={32}
              style={{ width: '100%', height: '99px' }}
            />
          </picture>
          <Box align="right">
            <Button color="inherit">About</Button>
            <Button color="inherit">Contact Us</Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          background: "#fff",
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#fff",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto", background: "#fff", color: "#000" }}>
          <List sx={{ marginTop: '60px'}}>
            {routes.map((route) => (
              <ListItem key={route.label} disablePadding>
                <ListItemButton component={Link} to={route.path}>
                  <ListItemIcon>
                    <Avatar
                      sx={{
                        bgcolor: grey[900],
                        width: 32,
                        height: 32,
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderColor: location.pathname === route.path ? 'secondary' : 'inherit',
                        borderStyle: location.pathname === route.path ? 'solid' : 'inherit'
                      }}
                    >
                      {route.iconText}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary={route.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box sx={{ flex: "1", marginTop: "64px", padding: "16px" }}>{children}</Box>
      <Box
      component="footer"
      sx={{
        backgroundColor: '#006666',
        padding: '16px',
        textAlign: 'center',
        color: '#ffffff',
      }}
    >
      <Typography variant="body2">
        © 2024 Publicis Sapient. All rights reserved.
      </Typography>
    </Box>
    </Box>
  );
}