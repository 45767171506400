import React, { useState } from 'react';
import { Box, Button, styled, CircularProgress } from '@mui/material';

const GenerateRequirementsButton = ({ isFileUploaded, isUrlAttached, generateRequirements,isDisabled, content }) => {
  const [requirementsGenerated, setRequirementsGenerated] = useState(false);
  const [loading,setLoading]=useState(false)
  const handleClick = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 5000);
    generateRequirements();
    setRequirementsGenerated(true);
  };

  const StyledBtn = styled(Button)(({ theme, disabled }) => ({
    color: disabled ? undefined : '#fff',
    background: disabled ? undefined : '#673ab7',
    '&:hover': {
      background: disabled ? undefined : '#5e35b1',
    },
  }));

  return (
    <Box display="flex" justifyContent="flex-end">
    <StyledBtn
      variant="contained"
      onClick={handleClick}
      
      disabled={isDisabled}
      sx={{ marginTop: '2em', textTransform: 'none', minWidth:'280px',minHeight:'40px' }}
    >
                   {loading? <CircularProgress size={20}/>: <span>{content}</span>}  

    </StyledBtn>
    </Box>
  );
};

export default GenerateRequirementsButton;
